.form-input {
  display: flex;
  flex-direction: column;

  label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    margin-bottom: 2px;
  }
  input {
    border: 1px solid #ccc;
    padding: 10px 8px;
    border-radius: 8px;
    width: 100%;
  }

  .error {
    color: red;
    font-size: 0.75rem;
  }

  @media (max-width: 768px) {
    label {
      font-size: 0.9rem;
    }

    input {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }

  @media (max-width: 480px) {
    label {
      font-size: 0.85rem;
    }

    input {
      font-size: 0.85rem;
    }
  }
}
