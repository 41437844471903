.header {
  width: 50%;
  // background-image: url("../assets/heroimagee.jpg");
  background-size:cover;
  background-position:center;
  background-color: #156cb1;
  display: flex;
  //   align-items: center;
  justify-content: center;
  // height: 100vh;
  @media (max-width: 1120px) {
    // background-repeat:no-repeat;
    // -webkit-background-size:cover;
    // -moz-background-size:cover;
    // -o-background-size:cover;
    // background-size:cover;
    // background-position:center;
    img{
      width: 100%;
    }
  }
  h1 {
    font-size: 3rem;
    color: white;
    margin-top: 20%;
    text-align: center;
    @media (max-width: 600px) {
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1120px) {
    flex: none;
    // height: 50vh;
    width: 100%;
    h1 {
      font-size: 2rem;
    }
  }
}
