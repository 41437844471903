.form-container {
  // flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow-y: scroll;
  padding-top: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
  .checkbox {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 1120px) {
    overflow: unset;
    // margin-top: -26px;
    // background: white;
    // border-top-right-radius: 34px;
    // border-top-left-radius: 34px;
  }

  h2 {
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #18152de5;
  }

  .participation-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 60%;

    @media (max-width: 600px) {
      width: 90%;
    }
  }
  .terms-link {
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: 1120px) {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    h2 {
      font-size: 1.5rem;
    }
    h1{
      font-size: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }
  }
}
