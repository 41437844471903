.submit-btn {
  padding: 0.75rem 1.5rem;
  background-color: #ffb800;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  width: max-content;
  overflow: hidden;

  &:hover {
    background-color: #ffb700;
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
  }
}
