body html div button p table thead {
  font-family: "Poppins", system-ui;
  font-style: normal;
}

.app {
  display: flex;
  height: 100vh;

  @media (max-width: 1120px) {
    flex-direction: column;
  }
}
