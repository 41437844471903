.terms-conditions {
  font-family: Arial, sans-serif;
  width: 50%;
  margin: 20px auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 20px;
    padding: 0;
  }
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
.termsConditionstext {
  font-family: "Poppins";
  text-align: center;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  color: #18152de5;
  @media (max-width: 768px) {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    font-size: 20px;
  }
}
.table-container {
  overflow-y: scroll;
  margin-bottom: 20px;
  font-family: "Poppins";
}

.terms-table {
  width: 100%;
  border-collapse: collapse;
  
  @media (max-width: 1120px) {
    width: 80%;
    margin: 0 auto;
  }
}

.terms-table th,
.terms-table td {
  border: 2px solid black;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  @media (max-width: 1120px) {
    font-size: 12px;
  }
  @media (max-width: 600px) {
    padding: 2px;
    font-size: 7px;
  }
}

.terms-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  border: 2px solid black;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 600px) {
    font-size: 7px;
  }
}

.terms-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.close-btn {
  padding: 10px 20px;
  background-color: #302d42;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Poppins";
}

.close-btn:hover {
  background-color: #6a1b9a;
}
