/* Modal overlay for desktop (right side only) */
.custom-modal-overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Dark transparent background */
  position: fixed;
  top: 0;
  left: 0;
  right: -50%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media (max-width: 768px) {
    right: 0;
  }
}

.custom-modal-content {
  background: white;
  padding: 2.5rem 1.5rem;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 340px;
  border-radius: 42px;

  @media (max-width: 768px) {
    /* Full-width modal content on mobile */
    width: 80%; /* Slight padding on mobile */
    max-width: none; /* Remove max-width on mobile */
    padding: 12px 8px;
  }
}

.modal-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content h2 {
  margin: 1.3rem 0;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #18152de5;

  @media (max-width: 768px) {
    font-size: 18px; /* Adjust heading size for mobile */
    margin: 16px 0;
  }
}

.modal-content p {
  margin-bottom: 2rem;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #18152de5;

  @media (max-width: 600px) {
    font-size: 10.5px; /* Adjust paragraph size for mobile */
    margin-bottom: 1rem;
  }
}

.ok-btn {
  padding: 0.5rem 2rem;
  background-color: #ffb800;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 3px;

  @media (max-width: 768px) {
    padding: 0.5rem 1.5rem; /* Slightly smaller button on mobile */
  }
}

.ok-btn:hover {
  background-color: #ffb800;
}
